import React, { useState, useEffect } from "react";
import "./Clients.css";

const Clients = () => {
  const [clientLogos, setClientLogos] = useState([]);
  const [totalWidth, setTotalWidth] = useState(0);

  useEffect(() => {
    let totalLogoWidth = 0;

    fetch("https://officeservice.co.il/php_files/get_client_images.php")
      .then((response) => response.json())
      .then((data) => {
        setClientLogos(data);
        data.forEach((logoSrc) => {
          const img = new Image();
          img.src = logoSrc;
          img.onload = () => {
            totalLogoWidth += img.width + 40; // Adding 40 for 20px padding on each side
            setTotalWidth(totalLogoWidth * 2); // Doubling the width for two .client-logos divs
          };
        });
        setTotalWidth(8,364.76)
      })
      .catch((error) => {
        console.error("There was an error fetching client images:", error);
      });
  }, []);

  return (
    <div id="clients-wrapper">
      <div className="our-clients">
        <p id="clients-title" className="clients-title">
          הם כבר עובדים איתנו
        </p>
        <div id="clients">
          <div className="client-logos-wrapper">
            <div className="client-logos">
              {clientLogos.map((logo, index) => (
                <img
                  key={index}
                  src={logo}
                  alt={`Client ${index + 1}`}
                  className="client-logo"
                />
              ))}
            </div>
            <div className="client-logos">
              {clientLogos.map((logo, index) => (
                <img
                  key={index}
                  src={logo}
                  alt={`Client ${index + 1}`}
                  className="client-logo"
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Clients;
