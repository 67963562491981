import React, { useEffect, useState } from "react";
import axios from "axios";
import { NavLink, Link } from "react-router-dom";
import "./MobileHeader.css";
//social icons
import facebook from "../images/social/facebook.png";
import whatsapp from "../images/social/whatsapp.png";
import instagram from "../images/social/instagram.png";
import SearchIcon from "./SearchIcon";

function MobileHeader() {
  const [isActive, setIsActive] = useState(false);
  const [search, setSearch] = useState("");
  const [results, setResults] = useState([]);
  const [cartCount, setCartCount] = useState(0);
  const [isSearchOpen, setSearchOpen] = useState(false);
  const searchInputRef = React.useRef();
  useEffect(() => {
    if (isSearchOpen) {
      document.documentElement.classList.add("no-scroll");
    } else {
      document.documentElement.classList.remove("no-scroll");
    }
  }, [isSearchOpen]);

  useEffect(() => {
    const updateCartCount = () => {
      const cartProducts =
        JSON.parse(localStorage.getItem("cartProducts")) || [];
      setCartCount(cartProducts.length);
    };

    updateCartCount();
    window.addEventListener("cartChange", updateCartCount);

    return () => window.removeEventListener("cartChange", updateCartCount);
  }, []);

  useEffect(() => {
    if (search !== "") {
      axios
        .get(`https://officeservice.co.il/php_files/search.php?search=${search}`)
        .then((res) => {
          setResults(res.data.slice(0, 3));
        });
    } else {
      setResults([]);
    }
  }, [search]);

  function resetSearch() {
    setSearch("");
    setResults([]);
    setSearchOpen(false);
  }

  function handleSearch(e) {
    setSearch(e.target.value);
  }

  function handleSearchSubmit(e) {
    e.preventDefault();
    window.location.href = `/תוצאות-חיפוש/${encodeURIComponent(search)}`;
    resetSearch();
    setSearchOpen(false); // Add this line
  }

  const handleClick = () => {
    if (isActive) {
      const links = document.querySelectorAll(".mobile-link");
      links.forEach((link, index) => {
        link.classList.remove("mobile-link-enter");
        link.classList.add("mobile-link-leave");
        setTimeout(
          () => link.classList.remove("mobile-link-leave"),
          500 + index * 100
        );
      });

      const socialIcons = document.querySelectorAll(
        "#mobile-social-container img"
      );
      socialIcons.forEach((icon) => {
        icon.classList.remove("img-enter");
        icon.classList.add("img-leave");
        setTimeout(() => icon.classList.remove("img-leave"), 500);
      });
    } else {
      const links = document.querySelectorAll(".mobile-link");
      links.forEach((link, index) => {
        setTimeout(() => {
          link.classList.add("mobile-link-enter");
        }, index * 100);
      });

      const socialIcons = document.querySelectorAll(
        "#mobile-social-container img"
      );
      socialIcons.forEach((icon, index) => {
        setTimeout(() => {
          icon.classList.add("img-enter");
        }, (index + links.length) * 100);
      });
    }

    setIsActive((prevState) => !prevState);
  };

  const handleLinkClick = () => {
    const links = document.querySelectorAll(".mobile-link");
    links.forEach((link, index) => {
      link.classList.remove("mobile-link-enter");
      link.classList.add("mobile-link-leave");
      setTimeout(
        () => link.classList.remove("mobile-link-leave"),
        500 + index * 100
      );
    });

    const socialIcons = document.querySelectorAll(
      "#mobile-social-container img"
    );
    socialIcons.forEach((icon) => {
      icon.classList.remove("img-enter");
      icon.classList.add("img-leave");
      setTimeout(() => icon.classList.remove("img-leave"), 500);
    });

    setIsActive(false);
  };
  const handleSearchSelect = () => {
    setSearchOpen(!isSearchOpen);
    if (!isSearchOpen) {
      setTimeout(() => {
        searchInputRef.current.focus();
      }, 0);
    }
  };
  const handleSearchExit = () => {
    setSearchOpen(false);
    resetSearch();
  };
  useEffect(() => {
    if (isSearchOpen) {
      document.getElementById("mobile-search-form").width = "100%";
    } else {
      document.getElementById("mobile-search-form").width = "0px";
    }
  }, [isSearchOpen]);
  useEffect(() => {
    if (isActive) {
      document.documentElement.classList.add("no-scroll");

      const links = document.querySelectorAll(".mobile-link");
      links.forEach((link, index) => {
        setTimeout(() => {
          link.classList.add("mobile-link-enter");
        }, index * 100);
      });

      const socialIcons = document.querySelectorAll(
        "#mobile-social-container img"
      );
      socialIcons.forEach((icon, index) => {
        setTimeout(() => {
          icon.classList.add("img-enter");
        }, (index + links.length) * 100);
      });
    } else {
      document.documentElement.classList.remove("no-scroll");

      const links = document.querySelectorAll(".mobile-link");
      links.forEach((link) => {
        link.classList.remove("mobile-link-enter");
      });

      const socialIcons = document.querySelectorAll(
        "#mobile-social-container img"
      );
      socialIcons.forEach((icon) => {
        icon.style.opacity = "0";
        icon.classList.remove("img-enter");
      });
    }
  }, [isActive]);

  return (
    <>
      <div
        id="overlay"
        style={{
          zIndex: "998",
          height: "100vh",
          width: "100vw",
          display: isSearchOpen ? "block" : "none",
          position: "absolute",
          backgroundColor: "rgba(0,0,0,0.5)",
        }}
        onClick={handleSearchExit}
      ></div>
      <div id="mobile-header-container">
        <Link to="/סל-מוצרים" id="mobile-cart">
          <i className="fas fa-shopping-cart mobile-cart-icon"></i>
          {cartCount > 0 && (
            <span key={cartCount} className="cart-count">
              {cartCount}
            </span>
          )}
        </Link>
        <div id="mobile-search" style={{width: "100%"}}>
          <SearchIcon handleSearchSelect={handleSearchSelect} />

          <div
            id="mobile-search-div"
            style={{
              position: "relative",
              zIndex: isSearchOpen ? "1003" : "1003",
            }}
          >
            <form onSubmit={handleSearchSubmit} style={{width: isSearchOpen ? "100vw" : "0", transition: "0.3s ease"}} id="mobile-search-form">
              <input
                ref={searchInputRef}
                type="text"
                id="mobile-search-input"
                placeholder="חיפוש באתר"
                value={search}
                onChange={handleSearch}
                autoComplete="off"
                style={{
                  width: "100%",
                  padding: isSearchOpen ? "5px" : "0px",
                }}
              />
            </form>
            {results.length > 0 && (
              <div className="mobile-search-results">
                {results.map((product) => (
                  <div className="mobile-search-result" key={product.id}>
                    <div
                      className="result-image"
                      style={{
                        backgroundImage: `url(${
                          product.colors
                            ? product.colorsImages.split(",")[0]
                            : product.mainImage
                        })`,
                      }}
                    ></div>

                    <Link to={`/מוצרים/${product.id}`} onClick={resetSearch}>
                      {product.productName}
                    </Link>
                  </div>
                ))}
                {results.length > 0 && (
                  <Link
                    to={`/תוצאות-חיפוש/${encodeURIComponent(search)}`}
                    onClick={resetSearch}
                    className="watch-results-style"
                  >
                    צפייה בכל התוצאות
                  </Link>
                )}
              </div>
            )}
          </div>
        </div>
        <div id="mobile-header">
          <div
            className={`menu-icon ${isActive ? "active" : ""}`}
            onClick={handleClick}
          >
            <div></div>
          </div>

          <div id="mobile-header-content">
            <Link to="/" className="mobile-link" onClick={handleLinkClick}>
              דף הבית
            </Link>
            <Link
              to="/מי-אנחנו"
              className="mobile-link"
              onClick={handleLinkClick}
            >
              אודותינו
            </Link>
            <Link
              to="/קטגוריות"
              className="mobile-link"
              onClick={handleLinkClick}
            >
              המוצרים שלנו
            </Link>
            <Link
              to="/תיק-עבודות"
              className="mobile-link"
              onClick={handleLinkClick}
            >
              תיק עבודות
            </Link>
            <Link
              to="/יצירת-קשר"
              className="mobile-contact mobile-link"
              onClick={handleClick}
            >
              יצירת קשר
            </Link>
            <div id="mobile-social-container">
              <a href="https://wa.me/972524243635">
                <img src={whatsapp} />
              </a>
              <a href="https://www.facebook.com/profile.php?id=100005175634037" target="_blank">
                <img src={facebook} />
              </a>
              <a href="https://www.instagram.com/office_gift_service/" target="_blank">
                <img src={instagram} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MobileHeader;
