import React, { useEffect } from "react";
import logo from "../images/logo.png";
import "./SideImg.css";

function SideImg() {
  useEffect(() => {
    const handleScroll = () => {
      const image = document.getElementById("side-img");

      // Calculate the rotation angle based on the scroll position
      const rotationAngle = (window.scrollY / window.innerHeight) * 360;
      // Apply the rotation using CSS transform property
      image.style.transform = `rotate(${rotationAngle}deg)`;

      // Check if the viewport width is at least 1200px
      if (window.matchMedia("(min-width: 1200px)").matches) {
        // If so, adjust the off-screen position of the image based on the scroll position
        if (window.scrollY >= 150) {
          image.style.left = "-125px"; // Half of 250px
        } else {
          image.style.left = "-250px";
        }
      } else {
        // If not, adjust the off-screen position of the image based on the scroll position
        if (window.scrollY >= window.innerHeight / 2) {
          image.style.left = "-100px"; // Half of 150px
        } else {
          image.style.left = "-200px";
        }
      }
    };

    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return <img src={logo} id="side-img" />;
}

export default SideImg;
