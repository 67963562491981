import React from "react";
import { FaHandPeace } from "react-icons/fa";

const SearchIcon = ({ size = 30 , handleSearchSelect}) => {
  const scale = size / 24; // Calculate the scale based on the desired size
  const translate = (24 - size) / 2; // Calculate the translation to center the icon
  const circleRadius = 6 * scale;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke="#282828"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      style={{
        position: "absolute",
        left: "30px",
        top: "50%",
        transform: "translateY(-50%)",
        zIndex: "1003",
      }}
      onClick={handleSearchSelect}
    >
      <circle cx="11" cy="11" r={circleRadius} />
      <line x1="21" y1="21" x2="16.65" y2="16.65" />
    </svg>
  );
};

export default SearchIcon;
