import React, { useState, useEffect } from "react";
import { useParams, useLocation, Link } from "react-router-dom";
import "./ProductPage.css";
const cartChangeEvent = new Event("cartChange");

const ProductPage = () => {
  const { id } = useParams();
  const location = useLocation();
  const [product, setProduct] = useState(
    location.state ? location.state.product : null
  );
  const [isLoading, setIsLoading] = useState(!product);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [isInCart, setIsInCart] = useState(false);

  useEffect(() => {
    const fetchProductData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          `https://officeservice.co.il/php_files/fetch_product.php?id=${id}`
        );
        const data = await response.json();
        setProduct(data);
      } catch (error) {
        console.error("Failed to fetch product:", error);
      } finally {
        setIsLoading(false);
      }
    };

    const cartProducts = JSON.parse(localStorage.getItem("cartProducts")) || [];
    setIsInCart(cartProducts.includes(id));

    // Fetch the product data only if there is no product in the location state
    if (!location.state || !location.state.product) {
      fetchProductData();
    }
  }, [id]);

  const handleCartClick = (e) => {
    e.preventDefault(); // Prevent navigation
    const cartProducts = JSON.parse(localStorage.getItem("cartProducts")) || [];
    if (cartProducts.includes(product.id)) {
      const newCartProducts = cartProducts.filter((id) => id !== product.id);
      localStorage.setItem("cartProducts", JSON.stringify(newCartProducts));
      setIsInCart(false);
    } else {
      cartProducts.push(product.id);
      localStorage.setItem("cartProducts", JSON.stringify(cartProducts));
      setIsInCart(true);
    }
    window.dispatchEvent(cartChangeEvent);
  };

  useEffect(() => {
    // Check if there is a product in the location state
    if (location.state && location.state.product) {
      // If so, update the product state
      setProduct(location.state.product);
    }
  }, [id, location.state]);

  return (
    <div className="product-page-container">
      {isLoading ? (
        <p>טוען מוצר...</p>
      ) : product ? (
        <>
          <div id="product-container">
            <div id="product-images-container">
              <img
                className="main-image"
                src={
                  product.colors
                    ? product.colorsImages[selectedImageIndex]
                    : product.mainImage
                }
                alt={product.productName}
              />
              <div className="thumbnail-container">
                {product.colors &&
                  product.colorsImages.map((image, index) => (
                    <img
                      key={index}
                      className={`thumbnail ${
                        index === selectedImageIndex ? "active" : ""
                      }`}
                      src={image}
                      alt={`Color variant ${index}`}
                      onClick={() => setSelectedImageIndex(index)}
                    />
                  ))}
              </div>
            </div>
            <div id="product-details">
              <p id="product-title">{product.productName}</p>
              <p
                id="product-description"
                dangerouslySetInnerHTML={{
                  __html: product.description.replace(/\n/g, "<br />"),
                }}
              />
              <button
                id="product-add-to-cart"
                className={isInCart ? "in-cart" : ""}
                onClick={handleCartClick}
              >
                {isInCart ? "המוצר בסל במוצרים" : "הוסף מוצר לסל"}
              </button>
            </div>
          </div>
        </>
      ) : (
        <p>המוצר לא נמצא</p>
      )}

      <div id="product-gallery-container">
        <p>בואו לראות דוגמאות אמיתיות למוצרים שעשינו למגוון לקוחותינו</p>
        <Link to="/תיק-עבודות">למעבר לתיק העבודות שלנו</Link>
      </div>
    </div>
  );
};

export default ProductPage;
