// AdminPage.js
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from '../components/firebase';
import AddCategory from './Admin_Components/AddCategory';
import AddProduct from './Admin_Components/AddProduct';
import AddImageToGallery from './Admin_Components/AddImageToGallery';
import './AdminPage.css';
import LeadsManager from "./Admin_Components/LeadsManager";
import AddClient from "./Admin_Components/AddClient";

const AdminPage = () => {
    const navigate = useNavigate();
    const [selectedOption, setSelectedOption] = useState('Add Category');

    useEffect(() => {
        if(!auth.currentUser) {
            navigate("/admin-login");
        }
    }, [navigate]);

    const handleSignout = () => {
        auth.signOut()
        .then(() => {
            navigate("/");
        })
        .catch(err => {
            console.error("Sign out error", err);
        });
    };

    const renderComponent = (selectedOption) => {
        switch (selectedOption) {
            case 'Add Category':
                console.log("Category selected!");
                return <AddCategory />;
            case 'Add Product':
                return <AddProduct />;
            case 'Add Image to Gallery':
                return <AddImageToGallery />;
            case 'Manage Leads':
                return <LeadsManager />;
            case 'Add Client':
                return <AddClient />;
            default:
                return <LeadsManager />;
        }
    }

    return (
        <div style={{padding: "20px", fontFamily: "Arial, sans-serif"}}>
            <h1>ברוך הבא, ארז</h1>
            <div style={{marginBottom: "20px"}}>
                <button className="admin-button" style={{marginRight: "10px"}} onClick={() => setSelectedOption('Add Category')}>הוספת קטגוריה</button>
                <button className="admin-button" style={{marginRight: "10px"}} onClick={() => setSelectedOption('Add Product')}>הוספת מוצר</button>
                <button className="admin-button" style={{marginRight: "10px"}} onClick={() => setSelectedOption('Add Image to Gallery')}>הוספת תמונה לתיק עבודות</button>
                <button className="admin-button" style={{marginRight: "10px"}} onClick={() => setSelectedOption('Manage Leads')}>ניהול לקוחות</button>
                <button className="admin-button" style={{marginRight: "10px"}} onClick={() => setSelectedOption('Add Client')}>הוסף לוגו לקוח</button>
            </div>
            {renderComponent(selectedOption)}
            <button className="admin-button" onClick={handleSignout}>יציאה מהחשבון</button>
        </div>
    );
};

export default AdminPage;
