import React, { useState, useEffect } from 'react';
import Header from './Header';
import MobileHeader from './MobileHeader';

function CheckHeader() {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1050);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 1050);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return isDesktop ? <Header /> : <MobileHeader />;
}

export default CheckHeader;
