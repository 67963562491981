import React from "react";
import "./AboutSection.css";
import { Link } from "react-router-dom";
import wave from "../../images/wave.svg";

import officeImage from "../../images/office.jpg"; // Replace with the actual image path

function AboutSection() {
  return (
    <div id="about-container">
      <div className="image-container">
        <img src={officeImage} alt="Office" className="office-image" />
      </div>
      <div className="text-container">
        <p id="about-title">מי אנחנו?</p>
        <p id="about-description">
          אופיס סרוויס הינה חברה שנוסדה ב-2010 המתמחה במיתוג וקידום מכירות בעולם
          העסקי. אנחנו מספקים שירות איכותי בהדפסה על מוצרים שונים, ונותנים
          פתרונות מקיפים במקום אחד.
        </p>
        <Link to="/מי-אנחנו" className="readMoreStyle">
          קרא עוד
        </Link>
      </div>
    </div>
  );
}

export default AboutSection;
