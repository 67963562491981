import React from "react";
import { motion } from "framer-motion";
import "./ContactSection.css";
import image from "../../images/handshake.jpg";

import instagram from "../../images/social/instagram.png";
import facebook from "../../images/social/facebook.png";

const ContactUs = () => {
  const handleSubmit = async (e) => {
    e.preventDefault();
    const name = e.target.elements.name.value;
    const email = e.target.elements.email.value;
    const phone = e.target.elements.phone.value;

    const response = await fetch(
      "https://officeservice.co.il/php_files/insert_contact.php",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name, email, phonenumber: phone }),
      }
    );
    const data = await response.json();

    console.log(data);
  };
  return (
    <div className="contact-section">
      <div id="home-contact-form-container">
        <div className="form-container">
          <h1 className="titleS">מעוניינים בהצעת מחיר או פרטים נוספים?</h1>
          <p className="description">
            השאירו פרטים בטופס ונציגינו יצרו איתכם קשר!
          </p>
          <form className="formS" onSubmit={handleSubmit}>
            <div className="input-container">
              <motion.input
                className="inputCon"
                type="text"
                id="name"
                required
                placeholder=" "
              />
              <label htmlFor="name" className="home-contact-label">
                שם
              </label>
            </div>
            <div className="input-container">
              <motion.input
                className="inputCon"
                type="tel"
                id="phone"
                required
                placeholder=" "
              />
              <label htmlFor="phone" className="home-contact-label">
                מס' טלפון
              </label>
            </div>
            <div className="input-container">
              <motion.input
                className="inputCon"
                type="email"
                id="email"
                required
                placeholder=" "
              />
              <label htmlFor="email" className="home-contact-label">
                אימייל
              </label>
            </div>
            <motion.button className="submit-button" type="submit">
              שלח
            </motion.button>
          </form>
        </div>
      </div>
      <div id="home-contact-details-container">
        <p id="contact-details-title">אופיס סרוויס</p>
        <p id="contact-details-description">החברה המובילה בארץ בתחום הקידום והשיווק</p>
        <p id="contact-details-social-title">תראו אותנו גם ברשתות:</p>
        <div id="contact-details-social-links">
          <a href="https://www.instagram.com/office_gift_service/" target="_blank">
            <img src={instagram} alt="instagram-link" />
          </a>
          <a href="https://www.facebook.com/profile.php?id=100005175634037" target="_blank">
          <img src={facebook} alt="instagram-link" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
