import React, { useEffect, useState, useRef } from "react";
import "./GalleryPage.css";

function GalleryPage() {
  const [images, setImages] = useState([]);
  const observer = useRef();

  useEffect(() => {
    fetchImages();
  }, []);

  useEffect(() => {
    observer.current = new IntersectionObserver(
      (entries) => {
        const visibleImages = [];
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const image = entry.target;
            visibleImages.push(image);
            observer.current.unobserve(image);
          }
        });

        // Sort the images by their order in the DOM
        visibleImages.sort((a, b) => a.dataset.index - b.dataset.index);

        // Animate the images like a wave
        visibleImages.forEach((image, index) => {
          setTimeout(() => {
            image.style.opacity = 1;
            image.style.transform = "translateY(0)";
          }, index * 150); // Increase the delay for each subsequent image
        });
      },
      { rootMargin: "-50px 0px" }
    );

    const images = document.querySelectorAll(".gallery-item");
    images.forEach((image) => {
      observer.current.observe(image);
    });

    return () => observer.current.disconnect();
  }, [images]);

  const fetchImages = async () => {
    try {
      const response = await fetch(
        "https://officeservice.co.il/php_files/get_gallery_images.php"
      );
      const data = await response.json();
      if (data && data.images) {
        setImages(data.images);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div id="gallery-page-container">
      <h1 className="gallery-title">קצת ממה שאנחנו עושים</h1>
      <div className="gallery-grid">
        {images.length > 0 ? (
          images.map((image, index) => (
            <div key={index} className="gallery-item">
              <img
                src={image.url}
                alt={image.title}
                className="gallery-image"
              />
              <div className="gallery-image-title">{image.title}</div>
            </div>
          ))
        ) : (
          <p>No images available</p>
        )}
      </div>
    </div>
  );
}

export default GalleryPage;
