import React, { useEffect, useRef } from 'react';
import './Cursor.css';

function Cursor() {
  const observer = useRef(null); // use a ref to persist the observer across renders
  
  useEffect(() => {
    const cursor = document.querySelector('.cursor');
    const cursorinner = document.querySelector('.cursor2');
    const targets = ['input', 'a', 'button'];

    function handleMouseMove(e) {
      const x = e.clientX;
      const y = e.clientY;
      cursor.style.transform = `translate3d(calc(${x}px - 50%), calc(${y}px - 50%), 0)`;
      cursorinner.style.left = x + 'px';
      cursorinner.style.top = y + 'px';
    }
    
    function handleMouseDown() {
      cursor.classList.add('click');
      cursorinner.classList.add('cursorinnerhover');
    }
    
    function handleMouseUp() {
      cursor.classList.remove('click');
      cursorinner.classList.remove('cursorinnerhover');
    }
    
    function handleMouseOver() {
      cursor.classList.add('hover');
    }
    
    function handleMouseLeave() {
      cursor.classList.remove('hover');
    }

    function addListenersToTargets() {
      targets.forEach(target => {
        const elements = document.querySelectorAll(target);
        elements.forEach(element => {
          element.addEventListener('mouseover', handleMouseOver);
          element.addEventListener('mouseleave', handleMouseLeave);
        });
      });
    }
    
    function removeListenersFromTargets() {
      targets.forEach(target => {
        const elements = document.querySelectorAll(target);
        elements.forEach(element => {
          element.removeEventListener('mouseover', handleMouseOver);
          element.removeEventListener('mouseleave', handleMouseLeave);
        });
      });
    }

    // Initiate the observer
    observer.current = new MutationObserver(() => {
      // Remove listeners before adding new ones to prevent duplicate listeners
      removeListenersFromTargets();
      addListenersToTargets();
    });

    // Observe changes to the document's body and its subtree
    observer.current.observe(document.body, { childList: true, subtree: true });

    // Attach initial listeners
    addListenersToTargets();

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mousedown', handleMouseDown);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      // Detach all listeners on unmount
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener('mouseup', handleMouseUp);
      removeListenersFromTargets();

      // Stop observing changes
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, []);

  return (
    <>
      <div className="cursor"></div>
      <div className="cursor2"></div>
    </>
  );
}

export default Cursor;
