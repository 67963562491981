import React from "react";
import styles from "./CategoryCard.module.css";
import CategoryCardItself from "./CategoryCardItself";

const CategoryCard = ({ categories, onCategorySelect, isLoading }) => {
  return (
    <div className={styles.cardContainer}>
      {categories.map((category) => (
        <CategoryCardItself
          key={category.id}
          onCategorySelect={onCategorySelect}
          category={category}
          isLoading={isLoading}
        />
      ))}
    </div>
  );
};
export default CategoryCard;
