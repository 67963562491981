import React from "react";
import styles from "./CategoryCard.module.css";

const CategoryCardItself = ({ category, onCategorySelect, isLoading }) => {
  console.log("isLoading: ", isLoading); // Log isLoading

  const handleClick = (e) => {
    if (isLoading) {
      e.preventDefault();
      e.stopPropagation(); // prevent event propagation
      console.log("Loading... click should not be registered."); // Log when click is not supposed to be registered
    } else {
      onCategorySelect(category);
    }
  };

  return (
    <div className={styles.cardLink} onClick={handleClick}>
      <div className={styles.card}>
        <div className={styles.overlay}></div>
        <p className={styles.title}>{category.categoryName}</p>
        <img
          className={styles.image}
          src={category.imageUrl}
          alt={category.categoryName}
        />
      </div>
    </div>
  );
};

export default CategoryCardItself;
