import React, { useState, useEffect, useRef } from "react";
import { BsCartPlus, BsCartFill } from "react-icons/bs";
import { CiHeart } from "react-icons/ci";
import { Link } from "react-router-dom";
import "./ProductCard.css";
const cartChangeEvent = new Event("cartChange");

const ProductCard = ({ product }) => {
  const [isLoved, setIsLoved] = useState(false);
  const [isInCart, setIsInCart] = useState(false);
  const [faBoxOpacity, setFaBoxOpacity] = useState(0);
  const [faBoxTop, setFaBoxTop] = useState("-100px");

  const cartButtonRef = useRef(null);
  const cardRef = useRef(null);

  const imageSource = product.colors
    ? product.colorsImages.split(",")[0]
    : product.mainImage;

  useEffect(() => {
    const cartProducts = JSON.parse(localStorage.getItem("cartProducts")) || [];
    setIsInCart(cartProducts.includes(product.id));

    if (cartButtonRef.current) {
      cartButtonRef.current.addEventListener("click", () => {
        cartButtonRef.current.classList.add("clicked");
      });
    }
  }, [product.id]);

  useEffect(() => {
    if (isInCart) {
      setFaBoxOpacity(1);
      setFaBoxTop("37%");
    } else {
      setFaBoxOpacity(0);
      setFaBoxTop("-10px");
    }
  }, [isInCart]);

  const handleCartClick = (e) => {
    e.preventDefault(); // Prevent navigation
    const cartProducts = JSON.parse(localStorage.getItem("cartProducts")) || [];
    if (cartProducts.includes(product.id)) {
      const newCartProducts = cartProducts.filter((id) => id !== product.id);
      localStorage.setItem("cartProducts", JSON.stringify(newCartProducts));
      setIsInCart(false);
    } else {
      cartProducts.push(product.id);
      localStorage.setItem("cartProducts", JSON.stringify(cartProducts));
      setIsInCart(true);
    }
    
    // Dispatch the custom event right after the localStorage update
    window.dispatchEvent(cartChangeEvent);
  };
  

  return (
    <Link
      to={{
        pathname: `/מוצרים/${product.id}`,
        state: { product: product },
      }}
      className="product-card-link"
    >
      <div ref={cardRef} className="product-card">
        <div className="product-image-container">
          <img src={imageSource} alt={product.productName} />
        </div>
        <h2>{product.productName}</h2>
        <button
          ref={cartButtonRef}
          onClick={handleCartClick}
          className="cart-button"
        >
          <i className="fas fa-shopping-cart"></i>
          <i
            className="fas fa-box"
            style={{ opacity: 1, top: faBoxTop }}
          ></i>
        </button>
      </div>
    </Link>
  );
};

export default ProductCard;
