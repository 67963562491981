import React, { useEffect, useState } from "react";
import "./LeadsManager.css";

function LeadsManager() {
  const [contacts, setContacts] = useState([]);

  useEffect(() => {
    fetchContacts();
  }, []);

  const fetchContacts = async () => {
    try {
      const response = await fetch(
        "https://officeservice.co.il/php_files/show_clients.php"
      );
      const data = await response.json();
      const processedData = data.map((contact) => ({
        ...contact,
        notes: contact.notes || "", // Set notes to an empty string if it's null
      }));
      setContacts(processedData);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleCheckboxChange = (index) => {
    setContacts((prevContacts) => {
      const newContacts = [...prevContacts];
      newContacts[index].talked = newContacts[index].talked === "1" ? "0" : "1";
      return newContacts;
    });
  };

  const handleNotesChange = (index, event) => {
    setContacts((prevContacts) => {
      const newContacts = [...prevContacts];
      newContacts[index].notes = event.target.value;
      return newContacts;
    });
  };

  const handleSaveChanges = async (contact) => {
    try {
      const response = await fetch(
        "https://officeservice.co.il/php_files/update_contact.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(contact),
        }
      );
      const data = await response.text();
      console.log(data);
      alert("שינויים עודכנו בהצלחה")
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleDeleteContact = async (id) => {
    if (!window.confirm("האם אתה בטוח שאתה רוצה למחוק את הלקוח הזה?")) {
      return;
    }
    try {
      const response = await fetch(
        "https://officeservice.co.il/php_files/delete_contact.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ id }),
        }
      );
      const data = await response.text();
      console.log(data);
      fetchContacts(); // Refresh the contacts
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div id="leads-manager-container">
      <h2 style={{ fontSize: "30px", fontWeight: "700" }}>ניהול לקוחות</h2>
      <table>
        <thead>
          <tr>
            <th>שם מלא</th>
            <th>אימייל</th>
            <th>מספר טלפון</th>
            <th>הודעה</th>
            <th>האם הייתה שיחה</th>
            <th>הערות</th>
            <th>פעולות</th>
          </tr>
        </thead>
        <tbody>
          {contacts.map((contact, index) => (
            <tr key={index}>
              <td>{contact.name}</td>
              <td>{contact.email}</td>
              <td>{contact.phonenumber}</td>
              <td style={{textAlign: "right", maxWidth: "400px"}}>{contact.message}</td>
              <td>
                <input
                  type="checkbox"
                  checked={contact.talked === "1"}
                  onChange={() => handleCheckboxChange(index)}
                />
                <p>{contact.talked === "1" ? "הייתה שיחה" : "לא הייתה שיחה"}</p>
              </td>
              
              <td>
                <input
                  type="text"
                  value={contact.notes}
                  onChange={(event) => handleNotesChange(index, event)}
                />
              </td>
              <td>
                <button onClick={() => handleSaveChanges(contact)}>
                  שמור שינויים
                </button>
                <button onClick={() => handleDeleteContact(contact.id)}>
                  מחק לקוח
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default LeadsManager;
