function RadioOptions({ name, options, selectedOption, onChange }) {
    return (
      <div>
        {options.map((option, index) => (
          <div key={index} id="slide-options-container">
            <input
              type="radio"
              name={name}
              value={option}
              onChange={onChange}
              checked={selectedOption === option}
            />
            <p style={{marginRight: "5px"}}>{option}</p>
          </div>
        ))}
      </div>
    );
  }
export default RadioOptions;