import React from "react";
import whatsapp from "../images/social/whatsapp.png";

function WhatsappFixed() {
  return (
    <div
      id="whatapp-fixed-container"
      style={{
        position: "fixed",
        bottom: "0",
        left: "0",
        zIndex: "999",
        paddingLeft: "20px",
        paddingBottom: "20px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        overflow: "visible",
      }}
    >
      <a href="https://wa.me/972524243635" target="_blank" style={{overflow: "visible"}}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            overflow: "visible",
          }}
        >
          <img
            src={whatsapp}
            alt="whatsapp"
            style={{ height: "70px", margin: "0", padding: "0" }}
          />
          <p
            style={{
              textAlign: "center",
              padding: "2px 7px",
              backgroundColor: "white",
              fontFamily: "Ariel",
              color: "#282828",
              borderRadius: "10px",
              boxShadow: "0px 0px 20px -10px #000000",
              overflow: "visible"
            }}
          >
            דברו איתנו בוואצאפ
          </p>
        </div>
      </a>
    </div>
  );
}

export default WhatsappFixed;
