import React, { useState, useRef } from "react";
import CategoryList from "./CategoryList";
import "./AddProduct.css";

const AddProduct = () => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [productName, setProductName] = useState("");
  const [description, setDescription] = useState("");
  const [categoryStack, setCategoryStack] = useState([]);
  const [colors, setColors] = useState([]);
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const fileInputRef = useRef(); // Create a ref
  const colorsInputRef = useRef([]); // Ref for color inputs
  const colorsImageRef = useRef([]); // Ref for color image inputs
  const [isFormDisabled, setIsFormDisabled] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUploading(true);

    const formData = new FormData();
    formData.append("productName", productName);
    formData.append("parentCategoryId", selectedCategory.id);
    formData.append("description", description);
    colors.forEach((color, index) => {
      const colorNameInput = document.querySelector(`#colorName${index}`);
      const colorImageInput = document.querySelector(`#colorImage${index}`);
      if (colorNameInput && colorImageInput) {
        formData.append(`colorName${index}`, colorNameInput.value);
        formData.append(`colorImage${index}`, colorImageInput.files[0]);
      }
    });
    for (var pair of formData.entries()) {
      console.log(pair[0] + ', ' + pair[1]);
    }
    // Only append the main image if it's not undefined
    formData.append("mainImage", fileInputRef.current.files[0] || "");

    fetch("https://officeservice.co.il/php_files/add_products.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setUploading(false);
        fileInputRef.current.value = "";
        setProductName("");
        setDescription("");
        setColors([]);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const removeColor = (indexToRemove) => {
    setColors(colors.filter((_, index) => index !== indexToRemove));
  };

  const handleCategorySelect = async (category) => {
    setCategoryStack([...categoryStack, selectedCategory]);
    setSelectedCategory(category);

    const response = await fetch(
      `https://officeservice.co.il/php_files/check_for_products.php?categoryId=${category.id}`
    );
    const data = await response.json();
    console.log(data);

    setIsFormDisabled(!data);
  };

  const handleBack = () => {
    const newCategoryStack = [...categoryStack];
    const lastCategory = newCategoryStack.pop();
    setSelectedCategory(lastCategory);
    setCategoryStack(newCategoryStack);
  };
  
  

  const addColor = () => {
    setColors([...colors, { name: "", imageUrl: "" }]);
  };

  return (
    <div className="add-product-container">
      <h2 style={{ fontSize: "30px", fontWeight: "700" }}>הוספת מוצר</h2>
      {uploading && <progress value={progress} max="100" />}
      <CategoryList
        onCategorySelect={handleCategorySelect}
        parentId={selectedCategory?.id}
      />
      {selectedCategory && (
        <button className="back-to-category" onClick={handleBack}>
          חזור ל-{categoryStack[categoryStack.length - 1]?.name || "ראשי"}
        </button>
      )}
      <form onSubmit={handleSubmit} className="add-product-form">
        <input
          type="text"
          id="productName"
          name="productName"
          value={productName}
          placeholder="שם המוצר"
          onChange={(e) => setProductName(e.target.value)}
          required
          disabled={isFormDisabled}
        />
        <textarea
          id="description"
          name="description"
          value={description}
          placeholder="תיאור המוצר"
          onChange={(e) => setDescription(e.target.value)}
          required
        ></textarea>
        {colors.map((color, index) => (
          <div key={index}>
            <input
              ref={(el) => (colorsInputRef.current[index] = el)}
              type="text"
              id={`colorName${index}`}
              name="colorName"
              placeholder="שם הצבע"
              required
            />
            <input
              ref={(el) => (colorsImageRef.current[index] = el)}
              type="file"
              id={`colorImage${index}`}
              name="colorImage"
              required
            />
            <button
              type="button"
              style={{ color: "black" }}
              onClick={() => removeColor(index)}
            >
              הסר צבע
            </button>
          </div>
        ))}
        <button type="button" onClick={addColor} style={{ color: "white", backgroundColor: "#00afee" }}>
          הוסף צבע
        </button>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <p>תמונת המוצר</p>
          <input
            type="file"
            ref={fileInputRef}
            required
            disabled={colors.length > 0}
          />
        </div>
        <button type="submit" disabled={!selectedCategory || isFormDisabled}>
          הוסף מוצר
        </button>
      </form>
    </div>
  );
};

export default AddProduct;
