import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Link } from "react-router-dom";
import "./SearchPage.css";
import SocialDetails from "../components/SocialDetails";

function SearchPage() {
  const { searchQuery } = useParams();
  const decodedSearchQuery = decodeURIComponent(searchQuery);

  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    axios
      .get(
        `https://officeservice.co.il/php_files/search.php?search=${decodedSearchQuery}`
      )
      .then((res) => {
        setSearchResults(res.data);
      });
  }, [searchQuery]);

  return (
    <div className="sp-container">
      <h1 className="sp-title">תוצאות חיפוש עבור: {searchQuery}</h1>
      <p className="sp-subtitle">הנה מה שמצאנו עבורך</p>
      {searchResults.length === 0 ? (
        <p className="sp-no-results">
          אוי, לא מצאנו תוצאות שתואמות לחיפוש שלך. נסה לחפש מילה או ביטוי אחר.
        </p>
      ) : (
        <>
          <p className="sp-results-found">{`נמצאו ${searchResults.length} תוצאות`}</p>
          <div className="sp-search-results">
            {searchResults.map((product) => (
              <div key={product.id} className="sp-product-item">
                <Link to={`/מוצרים/${product.id}`} className="sp-product-link">
                  <div className="sp-product-image-container">
                    <img
                      className="sp-product-image"
                      src={
                        product.colors
                          ? product.colorsImages.split(",")[0]
                          : product.mainImage
                      }
                      alt={product.productName}
                    />
                  </div>
                  <div className="sp-product-name">{product.productName}</div>
                </Link>
              </div>
            ))}
          </div>
        </>
      )}
      <div id="product-gallery-container">
        <p>בואו לראות דוגמאות אמיתיות למוצרים שעשינו למגוון לקוחותינו</p>
        <Link to="/תיק-עבודות">למעבר לתיק העבודות שלנו</Link>
      </div>
    </div>
  );
}

export default SearchPage;
