import React from "react";
import Hero from "../components/Home components/Hero";
import AboutSection from "../components/Home components/AboutSection";
import CategoriesTemplate from "../components/Home components/CategoriesTemplate";
import img from "../images/image.jpg";
import Clients from "../components/Home components/Clients";
import Carousel from "../components/Home components/Carousel";
import ContactUs from "../components/Home components/ContactSection";
import Footer from "../components/Footer";
import SocialDetails from "../components/SocialDetails";

function Home() {

  return (
    <div id="home-container" style={{ overflowY: "hidden" }}>
      <Hero />
      <CategoriesTemplate />
      <AboutSection />
      <Clients />
      <ContactUs />
    </div>
  );
}

export default Home;
