import React, { useEffect, useRef } from "react";
import "./TypingAnimation.css";

function TypingAnimation({ prefix, textArray, colorArray }) {
  const textRef = useRef();

  useEffect(() => {
    let arrayIndex = 0;
    let charIndex = 0;
    let isDeleting = false;
    let timer;

    const type = () => {
      if (isDeleting) {
        if (charIndex > 0) {
          charIndex--;
          updateText();
          timer = setTimeout(type, 100);
        } else {
          isDeleting = false;
          arrayIndex = (arrayIndex + 1) % textArray.length;
          timer = setTimeout(type, 500);
        }
      } else {
        if (charIndex < textArray[arrayIndex].length) {
          charIndex++;
          updateText();
          timer = setTimeout(type, 200);
        } else {
          isDeleting = true;
          timer = setTimeout(type, 1000);
        }
      }
    };

    const updateText = () => {
      textRef.current.textContent = textArray[arrayIndex].substring(
        0,
        charIndex
      );
      textRef.current.className = colorArray[arrayIndex];
    };

    timer = setTimeout(type, 500);

    return () => clearTimeout(timer);
  }, [textArray]);

  return (
    <div className="typing-animation">
      {prefix} <span ref={textRef}></span>
    </div>
  );
}

export default TypingAnimation;
