import React, { useEffect, useState } from "react";
import banner from "../../images/banner.jpg";
import Header from "../Header";
import logo from "../../images/logo.png";
import "./Hero.css";
import "animate.css";
import ScrollDown from "./ScrollDown";
import img from "../../images/heroCarousel/slide1.jpg";
import bannerAnimation from "../../videos/banner.mp4";
import bannerAnimation2 from "../../videos/banner_animation.gif";
import Form from "./Form";
import { Link } from "react-router-dom";

function Hero() {
  return (
    <div id="hero-container">
      <div id="hero-banner-container">
        <video autoPlay loop muted playsInline className="banner-animation" controls={false}>
          <source src={bannerAnimation} type="video/mp4" />
        </video>
      </div>
      <div id="hero-content-container">
        <div className="hero-content">
          <div className="hero-left-half">
            <Form />
          </div>
          <div className="hero-right-half">
            <p id="hero-description">אפשרויות מהירות:</p>
            <div id="hero-buttons-container">
              <Link to="/קטגוריות" className="hero-button" id="hero-categories">
                כל הקטגוריות
              </Link>
              <Link to="/תיק-עבודות" className="hero-button" id="hero-work">
                תיק עבודות
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
