import React, { useRef, useState } from "react";
import "./AddImageToGallery.css";

const AddImageToGallery = () => {
  const fileInputRef = useRef();
  const [title, setTitle] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("image", fileInputRef.current.files[0]);
    formData.append("title", title);

    const response = await fetch(
      "https://officeservice.co.il/php_files/add_images_to_gallery.php",
      {
        method: "POST",
        body: formData,
      }
    );
    const data = await response.json();

    console.log(data);

    if (response.ok) {
      alert("התמונה הועלתה בהצלחה!"); // Alert for successful upload
      setTitle(""); // Optional: Clear the title input after a successful upload
    } else {
      alert("אירעה שגיאה בהעלאת התמונה. אנא נסה שנית."); // Alert for a failed upload
    }
  };

  return (
    <div className="admin-add-image-container">
      <h2 className="add-image-title">הוספת תמונה לתיק עבודות</h2>
      <form onSubmit={handleSubmit} className="add-image-form">
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="כותרת התמונה"
          className="add-image-input-text"
          required
        />
        <input
          type="file"
          ref={fileInputRef}
          className="add-image-input-file"
          required
        />
        <button type="submit" className="add-image-button">
          העלאת תמונה
        </button>
      </form>
    </div>
  );
};

export default AddImageToGallery;
