import React, { useState, useEffect } from "react";
import { auth } from '../components/firebase';
import { useNavigate } from 'react-router-dom';
import './AdminLogin.css';

function AdminLogin() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) navigate("/admin");
        });
        return unsubscribe;
    }, [navigate]);

    const signInWithEmailAndPasswordHandler = (event, email, password) => {
        event.preventDefault();
        auth.signInWithEmailAndPassword(email, password)
        .catch(error => {
            setError("Error signing in with password and email!");
            console.error("Error signing in with password and email", error);
        });
    };

    const onChangeHandler = (event) => {
        const {name, value} = event.currentTarget;

        if(name === 'userEmail') {
            setEmail(value);
        }
        else if(name === 'userPassword'){
            setPassword(value);
        }
    };

    return (
        <div className="card-container">
            {error !== null && <div>{error}</div>}
            <form>
                <input type="email" placeholder="אימייל" name="userEmail" value={email} onChange={onChangeHandler} />
                <input type="password" placeholder="סיסמא" name="userPassword" value={password} onChange={onChangeHandler} />
                <button onClick={(event) => signInWithEmailAndPasswordHandler(event, email, password)}>היכנס</button>
            </form>
        </div>
    );
}

export default AdminLogin;