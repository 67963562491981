import React, { useState, useRef } from "react";
import "./AddClient.css";

function AddClient() {
  const [file, setFile] = useState(null);
  const [companyName, setCompanyName] = useState("");
  const formRef = useRef(null); // Create a reference to the form

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleCompanyNameChange = (event) => {
    setCompanyName(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("clientImage", file);
    formData.append("companyName", companyName);

    fetch("https://officeservice.co.il/php_files/upload_client_image.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          alert("הלוגו הועלה בהצלחה");
          formRef.current.reset(); // Reset the form
          setFile(null); // Reset the file state
          setCompanyName(""); // Reset the company name state
        } else {
          alert(data.message);
        }
      })
      .catch((error) => {
        console.error("הייתה בעיה עם העלאת הלוגו: ", error);
      });
  };

  return (
    <div className="admin-add-client-container">
      <h2 className="add-client-title">הוספת לוגו לקוח</h2>
      <form ref={formRef} onSubmit={handleSubmit} className="add-client-form">
        <input
          type="text"
          placeholder="שם חברה"
          value={companyName}
          onChange={handleCompanyNameChange}
          className="add-client-input-text"
          required
        />
        <input
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          className="add-client-input-file"
          required
        />
        <button type="submit" className="add-client-button">
          העלאת תמונה
        </button>
      </form>
    </div>
  );
}

export default AddClient;
