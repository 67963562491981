import React from "react";
import "./ScrollDown.css";
import { Link } from "react-router-dom";

function ScrollDown() {
  function handleClick() {
    const scrollHeight = window.innerHeight; // Get the viewport height

    window.scrollTo({
      top: scrollHeight,
      behavior: "smooth", // Use smooth scrolling behavior
    });
  }

  return (
    <section id="scrollSection" className="demo">
      <button onClick={handleClick}>
        <span></span>
        <span></span>
        <span></span>
        <p className="animate__bounce animate__animated">גלו עוד!</p>
      </button>
    </section>
  );
}

export default ScrollDown;
