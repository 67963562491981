import React from "react";
import TypingAnimation from "../components/TypingAnimation";
import "./AboutPage.css";

function AboutPage() {
  const texts = ["מיתוג", "הפקה", 'קד"מ', "מתנות"];
  const colors = [
    "color1",
    "color2",
    "color3",
    "color4",
  ];
  return (
    <div id="about-page-container">
      <TypingAnimation
        prefix="אנחנו מוכרים מוצרי "
        textArray={texts}
        colorArray={colors}
      />
      <div className="about-content-section">
        <h2 className="about-title">אודות אופיס סרוויס</h2>
        <p className="about-description">
          אופיס סרוויס הינה חברה שנוסדה ב-2010 המתמחה במיתוג וקידום מכירות בעולם
          העסקי.
        </p>
        <p className="about-description">
          אנחנו מייבאים, משווקים ומדפיסים על כל מוצר בכל צבע וצורה, והכל נעשה
          במקום אחד ללא פערי תיווך.
        </p>
        <h3 className="products-services-title">מוצרים ושירותים</h3>
        <p className="products-services-description">
          בחברתנו מגוון ענק של מוצרים שונים ומגוונים בקטגוריות שונות.
        </p>
        <p className="products-services-description">
          אצלנו תמצאוו את כל סוגי הטכנולוגיות להדפסה כגון הדפסת 4v, הדפסת עץ,
          סיבלימציה, חריטה בלייזר, רקמה ועוד.
        </p>
      </div>
    </div>
  );
}

export default AboutPage;
