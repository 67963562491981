// CategoryList.js
import React, { useState, useEffect } from 'react';
import './CategoryList.css';

const CategoryList = ({ onCategorySelect, parentId }) => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      let url = 'https://officeservice.co.il/php_files/admin_categories.php';
      if (parentId) {
        url += `?parentId=${parentId}`;
      }
      const response = await fetch(url);
      const categoriesData = await response.json();
      setCategories(categoriesData);
    };
    fetchCategories();
  }, [parentId]);
  

  return (
    <div>
      <h3>בחר קטגוריה:</h3>
      {categories.map(category => (
        <div className="category-item" key={category.id} onClick={() => onCategorySelect(category)}>
          {category.categoryName}
        </div>
      ))}
    </div>
  );
};

export default CategoryList;
