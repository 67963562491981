import React from "react";
import "./HeaderContactButton.css";
import { Link } from "react-router-dom";

function HeaderContactButton() {
  return (
    <div className="buttons">
      <Link className="btn" to="/יצירת-קשר">
        <span></span>
        <p data-text="צרו איתנו קשר!" data-title="נשמח לשמוע מכם"></p>
      </Link>
    </div>
  );
}

export default HeaderContactButton;
