import React, { useState, useEffect } from "react";
import CategoryCard from "./CategoryCard";
import "./CategoriesTemplate.css";
import { useNavigate, useParams } from "react-router-dom";
import CategoryCardItself from "./CategoryCardItself";

const CategoriesTemplate = () => {
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();

  const handleCategorySelect = (category) => {
    navigate(`/קטגוריות/${category.id}`);
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(
          `https://officeservice.co.il/php_files/fetch_inventory.php?parentId=main&limit=6`
        );
        const responseJson = await response.json();
        setCategories(responseJson.data);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchCategories();
  }, []);

  return (
    <div className="categories-container">
      <p id="categories-title" className="title">
        קטגוריות מובילות
      </p>
      <div className="category-cards">
        {categories.map((category) => (
          <CategoryCardItself
            key={category.id}
            category={category}
            onCategorySelect={handleCategorySelect}
          />
        ))}
      </div>
    </div>
  );
};

export default CategoriesTemplate;
